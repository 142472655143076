/* tslint:disable */
/* eslint-disable */
/**
 * ErSys Backend Public
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {AccountType}
     * @memberof Account
     */
    'accountType': AccountType;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'billingAddressLineOne': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'billingAddressLineTwo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'billingCity': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'billingCountry': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'billingEmail': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'billingFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'billingLastName': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'billingPhone': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'billingPostal': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'billingState': string;
    /**
     * 
     * @type {AccountCreditCardToken}
     * @memberof Account
     */
    'creditCardToken': AccountCreditCardToken | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'custom1'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    'graduationYear': number;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    'isActive'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    'isHung'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    'locationId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'primaryEmail': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'primaryFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'primaryLastName': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'primaryPhone': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'room'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    'storeId': number;
}
/**
 * 
 * @export
 * @interface AccountCreationRequest
 */
export interface AccountCreationRequest {
    /**
     * 
     * @type {AccountType}
     * @memberof AccountCreationRequest
     */
    'accountType': AccountType;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationRequest
     */
    'billingAddressLineOne': string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationRequest
     */
    'billingAddressLineTwo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationRequest
     */
    'billingCity': string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationRequest
     */
    'billingCountry': string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationRequest
     */
    'billingEmail': string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationRequest
     */
    'billingFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationRequest
     */
    'billingLastName': string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationRequest
     */
    'billingPhone': string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationRequest
     */
    'billingPostal': string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationRequest
     */
    'billingState': string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationRequest
     */
    'custom1'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountCreationRequest
     */
    'graduationYear': number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountCreationRequest
     */
    'isHung'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof AccountCreationRequest
     */
    'locationId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationRequest
     */
    'primaryEmail': string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationRequest
     */
    'primaryFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationRequest
     */
    'primaryLastName': string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationRequest
     */
    'primaryPhone': string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreationRequest
     */
    'room'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountCreationRequest
     */
    'storeId': number;
    /**
     * 
     * @type {Array<PublicUserLinkCreationRequest>}
     * @memberof AccountCreationRequest
     */
    'usersToLink': Array<PublicUserLinkCreationRequest>;
}
/**
 * 
 * @export
 * @interface AccountCreationResponse
 */
export interface AccountCreationResponse {
    /**
     * 
     * @type {Account}
     * @memberof AccountCreationResponse
     */
    'account': Account;
    /**
     * 
     * @type {Session}
     * @memberof AccountCreationResponse
     */
    'session': Session;
}
/**
 * 
 * @export
 * @interface AccountCreditCardToken
 */
export interface AccountCreditCardToken {
    /**
     * 
     * @type {string}
     * @memberof AccountCreditCardToken
     */
    'brand': string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreditCardToken
     */
    'created': string;
    /**
     * 
     * @type {number}
     * @memberof AccountCreditCardToken
     */
    'expirationMonth': number;
    /**
     * 
     * @type {number}
     * @memberof AccountCreditCardToken
     */
    'expirationYear': number;
    /**
     * 
     * @type {number}
     * @memberof AccountCreditCardToken
     */
    'lastFour': number;
}
/**
 * 
 * @export
 * @interface AccountLookup
 */
export interface AccountLookup {
    /**
     * 
     * @type {number}
     * @memberof AccountLookup
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AccountLookup
     */
    'primaryEmail': string;
    /**
     * 
     * @type {string}
     * @memberof AccountLookup
     */
    'primaryFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof AccountLookup
     */
    'primaryLastName': string;
    /**
     * 
     * @type {number}
     * @memberof AccountLookup
     */
    'storeID': number;
}
/**
 * 
 * @export
 * @interface AccountLookupSet
 */
export interface AccountLookupSet {
    /**
     * 
     * @type {Array<AccountLookup>}
     * @memberof AccountLookupSet
     */
    'accounts': Array<AccountLookup>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AccountType = {
    Commercial: 'commercial',
    Student: 'student',
    Faculty: 'faculty',
    LinenRental: 'linen_rental',
    Other: 'other'
} as const;

export type AccountType = typeof AccountType[keyof typeof AccountType];


/**
 * 
 * @export
 * @interface CheckPassPhraseRequest
 */
export interface CheckPassPhraseRequest {
    /**
     * 
     * @type {string}
     * @memberof CheckPassPhraseRequest
     */
    'passphrase': string;
}
/**
 * 
 * @export
 * @interface ChecklistEntry
 */
export interface ChecklistEntry {
    /**
     * 
     * @type {string}
     * @memberof ChecklistEntry
     */
    'createdDate'?: string | null;
    /**
     * 
     * @type {Array<ChecklistEntryDetail>}
     * @memberof ChecklistEntry
     */
    'details': Array<ChecklistEntryDetail>;
    /**
     * 
     * @type {number}
     * @memberof ChecklistEntry
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ChecklistEntry
     */
    'specialInstructions'?: string | null;
}
/**
 * 
 * @export
 * @interface ChecklistEntryDetail
 */
export interface ChecklistEntryDetail {
    /**
     * 
     * @type {number}
     * @memberof ChecklistEntryDetail
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChecklistEntryDetail
     */
    'isWdf'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ChecklistEntryDetail
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ChecklistEntryDetail
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface ChecklistSet
 */
export interface ChecklistSet {
    /**
     * 
     * @type {Array<ChecklistEntry>}
     * @memberof ChecklistSet
     */
    'checklists': Array<ChecklistEntry>;
}
/**
 * 
 * @export
 * @interface ClientError
 */
export interface ClientError {
    /**
     * 
     * @type {string}
     * @memberof ClientError
     */
    'additionalData'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientError
     */
    'detail': string;
    /**
     * 
     * @type {string}
     * @memberof ClientError
     */
    'instance': string;
    /**
     * 
     * @type {number}
     * @memberof ClientError
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof ClientError
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ClientError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CountrySet
 */
export interface CountrySet {
    /**
     * 
     * @type {Array<Country>}
     * @memberof CountrySet
     */
    'countries': Array<Country>;
}
/**
 * 
 * @export
 * @interface CreditCardToken
 */
export interface CreditCardToken {
    /**
     * 
     * @type {string}
     * @memberof CreditCardToken
     */
    'brand': string;
    /**
     * 
     * @type {string}
     * @memberof CreditCardToken
     */
    'created': string;
    /**
     * 
     * @type {number}
     * @memberof CreditCardToken
     */
    'expirationMonth': number;
    /**
     * 
     * @type {number}
     * @memberof CreditCardToken
     */
    'expirationYear': number;
    /**
     * 
     * @type {number}
     * @memberof CreditCardToken
     */
    'lastFour': number;
}
/**
 * 
 * @export
 * @interface CustomerServiceRequest
 */
export interface CustomerServiceRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerServiceRequest
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerServiceRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerServiceRequest
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerServiceRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerServiceRequest
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerServiceRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerServiceRequest
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerServiceRequest
     */
    'postal': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerServiceRequest
     */
    'preferredContactType': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerServiceRequest
     */
    'request': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerServiceRequest
     */
    'state': string;
}
/**
 * 
 * @export
 * @interface DiscountCode
 */
export interface DiscountCode {
    /**
     * 
     * @type {string}
     * @memberof DiscountCode
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof DiscountCode
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DiscountCode
     */
    'isPercentOfTotalOrder'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DiscountCode
     */
    'isPercentage'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof DiscountCode
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface EmailClubRequest
 */
export interface EmailClubRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailClubRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof EmailClubRequest
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof EmailClubRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface EmploymentRequest
 */
export interface EmploymentRequest {
    /**
     * 
     * @type {string}
     * @memberof EmploymentRequest
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof EmploymentRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof EmploymentRequest
     */
    'comments': string;
    /**
     * 
     * @type {string}
     * @memberof EmploymentRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof EmploymentRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EmploymentRequest
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof EmploymentRequest
     */
    'position': string;
    /**
     * 
     * @type {string}
     * @memberof EmploymentRequest
     */
    'postal': string;
    /**
     * 
     * @type {string}
     * @memberof EmploymentRequest
     */
    'state': string;
}
/**
 * 
 * @export
 * @interface LinkedAccount
 */
export interface LinkedAccount {
    /**
     * 
     * @type {string}
     * @memberof LinkedAccount
     */
    'accountType': string;
    /**
     * 
     * @type {string}
     * @memberof LinkedAccount
     */
    'first': string;
    /**
     * 
     * @type {number}
     * @memberof LinkedAccount
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LinkedAccount
     */
    'last': string;
    /**
     * 
     * @type {number}
     * @memberof LinkedAccount
     */
    'store': number;
    /**
     * 
     * @type {string}
     * @memberof LinkedAccount
     */
    'storeName': string;
    /**
     * 
     * @type {string}
     * @memberof LinkedAccount
     */
    'storePrefix': string;
}
/**
 * 
 * @export
 * @interface LinkedAccountSet
 */
export interface LinkedAccountSet {
    /**
     * 
     * @type {Array<LinkedAccount>}
     * @memberof LinkedAccountSet
     */
    'accounts': Array<LinkedAccount>;
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface LocationSet
 */
export interface LocationSet {
    /**
     * 
     * @type {Array<Location>}
     * @memberof LocationSet
     */
    'locations': Array<Location>;
}
/**
 * 
 * @export
 * @interface MinimalStore
 */
export interface MinimalStore {
    /**
     * 
     * @type {string}
     * @memberof MinimalStore
     */
    'fullName': string;
    /**
     * 
     * @type {number}
     * @memberof MinimalStore
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MinimalStore
     */
    'prefix': string;
}
/**
 * 
 * @export
 * @interface MinimalStoreSet
 */
export interface MinimalStoreSet {
    /**
     * 
     * @type {Array<MinimalStore>}
     * @memberof MinimalStoreSet
     */
    'stores': Array<MinimalStore>;
}
/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {Array<OrderDiscountDetail>}
     * @memberof Order
     */
    'discounts': Array<OrderDiscountDetail>;
    /**
     * 
     * @type {Array<OrderFeeDetail>}
     * @memberof Order
     */
    'fees': Array<OrderFeeDetail>;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'firstPayment': number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'orderType': string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'paymentPlan'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'paymentType': string;
    /**
     * 
     * @type {Array<OrderPlanDetail>}
     * @memberof Order
     */
    'plans': Array<OrderPlanDetail>;
    /**
     * 
     * @type {Array<OrderSessionDetail>}
     * @memberof Order
     */
    'sessions': Array<OrderSessionDetail>;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface OrderCreditCard
 */
export interface OrderCreditCard {
    /**
     * 
     * @type {string}
     * @memberof OrderCreditCard
     */
    'brand': string;
    /**
     * 
     * @type {number}
     * @memberof OrderCreditCard
     */
    'expirationMonth': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCreditCard
     */
    'expirationYear': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCreditCard
     */
    'lastFour': number;
    /**
     * 
     * @type {string}
     * @memberof OrderCreditCard
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface OrderDiscountDetail
 */
export interface OrderDiscountDetail {
    /**
     * 
     * @type {string}
     * @memberof OrderDiscountDetail
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDiscountDetail
     */
    'isPercentOfTotalOrder'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDiscountDetail
     */
    'isPercentage'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDiscountDetail
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderDiscountDetail
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface OrderFeeDetail
 */
export interface OrderFeeDetail {
    /**
     * 
     * @type {string}
     * @memberof OrderFeeDetail
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof OrderFeeDetail
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface OrderPlan
 */
export interface OrderPlan {
    /**
     * 
     * @type {number}
     * @memberof OrderPlan
     */
    'accountId': number;
    /**
     * 
     * @type {Array<OrderPlanPriceAdjustment>}
     * @memberof OrderPlan
     */
    'credits': Array<OrderPlanPriceAdjustment>;
    /**
     * 
     * @type {Array<OrderPlanDiscountDisplay>}
     * @memberof OrderPlan
     */
    'discounts': Array<OrderPlanDiscountDisplay>;
    /**
     * 
     * @type {Array<OrderPlanPriceAdjustment>}
     * @memberof OrderPlan
     */
    'fees': Array<OrderPlanPriceAdjustment>;
    /**
     * 
     * @type {Array<OrderPlanPaymentDisplay>}
     * @memberof OrderPlan
     */
    'payments': Array<OrderPlanPaymentDisplay>;
    /**
     * 
     * @type {Array<OrderPlanPlanDisplay>}
     * @memberof OrderPlan
     */
    'plans': Array<OrderPlanPlanDisplay>;
    /**
     * 
     * @type {number}
     * @memberof OrderPlan
     */
    'storeId': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPlan
     */
    'subtotal': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPlan
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface OrderPlanDetail
 */
export interface OrderPlanDetail {
    /**
     * 
     * @type {boolean}
     * @memberof OrderPlanDetail
     */
    'isAddon'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof OrderPlanDetail
     */
    'planId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderPlanDetail
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface OrderPlanDiscountDisplay
 */
export interface OrderPlanDiscountDisplay {
    /**
     * 
     * @type {string}
     * @memberof OrderPlanDiscountDisplay
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPlanDiscountDisplay
     */
    'discountCode': string;
    /**
     * 
     * @type {number}
     * @memberof OrderPlanDiscountDisplay
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface OrderPlanPaymentDisplay
 */
export interface OrderPlanPaymentDisplay {
    /**
     * 
     * @type {string}
     * @memberof OrderPlanPaymentDisplay
     */
    'futurePayDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderPlanPaymentDisplay
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface OrderPlanPlanDisplay
 */
export interface OrderPlanPlanDisplay {
    /**
     * 
     * @type {number}
     * @memberof OrderPlanPlanDisplay
     */
    'globalPlanId': number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPlanPlanDisplay
     */
    'isAddon'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPlanPlanDisplay
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof OrderPlanPlanDisplay
     */
    'storePlanId': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPlanPlanDisplay
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface OrderPlanPriceAdjustment
 */
export interface OrderPlanPriceAdjustment {
    /**
     * 
     * @type {string}
     * @memberof OrderPlanPriceAdjustment
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof OrderPlanPriceAdjustment
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface OrderPlanRequest
 */
export interface OrderPlanRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderPlanRequest
     */
    'accountId': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderPlanRequest
     */
    'discountCodes': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OrderPlanRequest
     */
    'paymentPlanId'?: number | null;
    /**
     * 
     * @type {Array<OrderPlanRequestPlan>}
     * @memberof OrderPlanRequest
     */
    'plans': Array<OrderPlanRequestPlan>;
}
/**
 * 
 * @export
 * @interface OrderPlanRequestPlan
 */
export interface OrderPlanRequestPlan {
    /**
     * 
     * @type {number}
     * @memberof OrderPlanRequestPlan
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPlanRequestPlan
     */
    'isAddon'?: boolean | null;
}
/**
 * 
 * @export
 * @interface OrderRequest
 */
export interface OrderRequest {
    /**
     * 
     * @type {OrderCreditCard}
     * @memberof OrderRequest
     */
    'creditCardTokenDto': OrderCreditCard;
    /**
     * 
     * @type {boolean}
     * @memberof OrderRequest
     */
    'isRenewal'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderRequest
     */
    'isUpgrade'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderRequest
     */
    'needsBag'?: boolean | null;
    /**
     * 
     * @type {OrderPlanRequest}
     * @memberof OrderRequest
     */
    'order': OrderPlanRequest;
    /**
     * 
     * @type {string}
     * @memberof OrderRequest
     */
    'orderType': string;
    /**
     * 
     * @type {string}
     * @memberof OrderRequest
     */
    'paymentType': string;
}
/**
 * 
 * @export
 * @interface OrderSessionDetail
 */
export interface OrderSessionDetail {
    /**
     * 
     * @type {string}
     * @memberof OrderSessionDetail
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderSessionDetail
     */
    'publicName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderSessionDetail
     */
    'startDate': string;
}
/**
 * 
 * @export
 * @interface PasswordChangeRequest
 */
export interface PasswordChangeRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordChangeRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordChangeRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface PatchAction
 */
export interface PatchAction {
    /**
     * 
     * @type {string}
     * @memberof PatchAction
     */
    'from'?: string | null;
    /**
     * 
     * @type {PatchOperationType}
     * @memberof PatchAction
     */
    'operationType': PatchOperationType;
    /**
     * 
     * @type {string}
     * @memberof PatchAction
     */
    'to': string;
    /**
     * 
     * @type {any}
     * @memberof PatchAction
     */
    'value'?: any | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PatchOperationType = {
    Replace: 'Replace',
    Copy: 'Copy',
    Swap: 'Swap'
} as const;

export type PatchOperationType = typeof PatchOperationType[keyof typeof PatchOperationType];


/**
 * 
 * @export
 * @interface PatchRequest
 */
export interface PatchRequest {
    /**
     * 
     * @type {Array<PatchAction>}
     * @memberof PatchRequest
     */
    'actions'?: Array<PatchAction>;
}
/**
 * 
 * @export
 * @interface PaymentPlan
 */
export interface PaymentPlan {
    /**
     * 
     * @type {number}
     * @memberof PaymentPlan
     */
    'adminFee': number;
    /**
     * 
     * @type {Array<PaymentPlanDate>}
     * @memberof PaymentPlan
     */
    'dates': Array<PaymentPlanDate>;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlan
     */
    'firstPaymentPercentage': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlan
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentPlan
     */
    'isPublic'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentPlan
     */
    'isRenewalOnly'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentPlan
     */
    'isUpgradeOnly'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlan
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlan
     */
    'publicName': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlan
     */
    'validUntil': string;
}
/**
 * 
 * @export
 * @interface PaymentPlanDate
 */
export interface PaymentPlanDate {
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanDate
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanDate
     */
    'payDate': string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanDate
     */
    'percent': number;
}
/**
 * 
 * @export
 * @interface PaymentPlanSet
 */
export interface PaymentPlanSet {
    /**
     * 
     * @type {Array<PaymentPlan>}
     * @memberof PaymentPlanSet
     */
    'plans': Array<PaymentPlan>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PlanCollectionType = {
    Order: 'order',
    Upgrade: 'upgrade',
    None: 'none'
} as const;

export type PlanCollectionType = typeof PlanCollectionType[keyof typeof PlanCollectionType];


/**
 * 
 * @export
 * @interface PostalData
 */
export interface PostalData {
    /**
     * 
     * @type {string}
     * @memberof PostalData
     */
    'city': string;
    /**
     * 
     * @type {number}
     * @memberof PostalData
     */
    'postalCode': number;
    /**
     * 
     * @type {string}
     * @memberof PostalData
     */
    'state': string;
}
/**
 * 
 * @export
 * @interface PricingRequest
 */
export interface PricingRequest {
    /**
     * 
     * @type {string}
     * @memberof PricingRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PricingRequest
     */
    'first': string;
    /**
     * 
     * @type {string}
     * @memberof PricingRequest
     */
    'last': string;
    /**
     * 
     * @type {number}
     * @memberof PricingRequest
     */
    'storeID': number;
    /**
     * 
     * @type {string}
     * @memberof PricingRequest
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface Proration
 */
export interface Proration {
    /**
     * 
     * @type {number}
     * @memberof Proration
     */
    'cost': number;
    /**
     * 
     * @type {number}
     * @memberof Proration
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface PublicUserLinkCreationRequest
 */
export interface PublicUserLinkCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicUserLinkCreationRequest
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof PublicUserLinkCreationRequest
     */
    'isNew'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PublicUserLinkCreationRequest
     */
    'isPasswordAutoGenerated'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PublicUserLinkCreationRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface RecentOrder
 */
export interface RecentOrder {
    /**
     * 
     * @type {string}
     * @memberof RecentOrder
     */
    'date'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RecentOrder
     */
    'globalPlan'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RecentOrder
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RecentOrder
     */
    'store': string;
}
/**
 * 
 * @export
 * @interface RenewalOrder
 */
export interface RenewalOrder {
    /**
     * 
     * @type {string}
     * @memberof RenewalOrder
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenewalOrder
     */
    'first': string;
    /**
     * 
     * @type {number}
     * @memberof RenewalOrder
     */
    'globalPlan'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RenewalOrder
     */
    'last': string;
    /**
     * 
     * @type {string}
     * @memberof RenewalOrder
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RenewalOrder
     */
    'store': string;
}
/**
 * 
 * @export
 * @interface ServerError
 */
export interface ServerError {
    /**
     * 
     * @type {string}
     * @memberof ServerError
     */
    'additionalData'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServerError
     */
    'detail': string;
    /**
     * 
     * @type {string}
     * @memberof ServerError
     */
    'instance': string;
    /**
     * 
     * @type {number}
     * @memberof ServerError
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof ServerError
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ServerError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface Session
 */
export interface Session {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Session
     */
    'claims': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface SessionRequest
 */
export interface SessionRequest {
    /**
     * 
     * @type {string}
     * @memberof SessionRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SessionRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface Store
 */
export interface Store {
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'customOne': string | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'customOneType': string | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'fullName': string;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    'hidePricing': boolean;
    /**
     * 
     * @type {number}
     * @memberof Store
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    'isCustomOneEnabled'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    'isLocationEnabledOnOrder'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    'isLocationMandatoryOnOrder'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    'isPassphraseRequired'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    'isRoomEnabledOnOrder'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'prefix': string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'shortName': string;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    'showTermsOfService': boolean;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'specifics': string | null;
    /**
     * 
     * @type {StoreType}
     * @memberof Store
     */
    'storeType': StoreType;
    /**
     * 
     * @type {number}
     * @memberof Store
     */
    'taxRate': number;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'termsOfService': string | null;
}
/**
 * 
 * @export
 * @interface StoreLookup
 */
export interface StoreLookup {
    /**
     * 
     * @type {string}
     * @memberof StoreLookup
     */
    'fullName': string;
    /**
     * 
     * @type {number}
     * @memberof StoreLookup
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof StoreLookup
     */
    'prefix': string;
    /**
     * 
     * @type {string}
     * @memberof StoreLookup
     */
    'shortName': string;
}
/**
 * 
 * @export
 * @interface StoreLookupSet
 */
export interface StoreLookupSet {
    /**
     * 
     * @type {Array<StoreLookup>}
     * @memberof StoreLookupSet
     */
    'stores': Array<StoreLookup>;
}
/**
 * 
 * @export
 * @interface StorePlan
 */
export interface StorePlan {
    /**
     * 
     * @type {number}
     * @memberof StorePlan
     */
    'currentCost': number;
    /**
     * 
     * @type {string}
     * @memberof StorePlan
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StorePlan
     */
    'details'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StorePlan
     */
    'expiration': string;
    /**
     * 
     * @type {number}
     * @memberof StorePlan
     */
    'futureCost': number;
    /**
     * 
     * @type {number}
     * @memberof StorePlan
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof StorePlan
     */
    'isAvailableAsAddon'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof StorePlan
     */
    'isFaculty'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof StorePlan
     */
    'isProrationShutoff'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof StorePlan
     */
    'isRenewal'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof StorePlan
     */
    'isVisible'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof StorePlan
     */
    'planId': number;
    /**
     * 
     * @type {string}
     * @memberof StorePlan
     */
    'publicName': string;
    /**
     * 
     * @type {number}
     * @memberof StorePlan
     */
    'storeCost': number;
    /**
     * 
     * @type {number}
     * @memberof StorePlan
     */
    'storeId': number;
    /**
     * 
     * @type {string}
     * @memberof StorePlan
     */
    'subtext'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StorePlan
     */
    'visualOrder': number;
}
/**
 * 
 * @export
 * @interface StorePlanAddon
 */
export interface StorePlanAddon {
    /**
     * 
     * @type {number}
     * @memberof StorePlanAddon
     */
    'currentCost': number;
    /**
     * 
     * @type {number}
     * @memberof StorePlanAddon
     */
    'defaultCost': number;
    /**
     * 
     * @type {string}
     * @memberof StorePlanAddon
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof StorePlanAddon
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof StorePlanAddon
     */
    'planId': number;
    /**
     * 
     * @type {string}
     * @memberof StorePlanAddon
     */
    'publicName': string;
    /**
     * 
     * @type {number}
     * @memberof StorePlanAddon
     */
    'storePlanId': number;
}
/**
 * 
 * @export
 * @interface StorePlanAddonSet
 */
export interface StorePlanAddonSet {
    /**
     * 
     * @type {Array<StorePlanAddon>}
     * @memberof StorePlanAddonSet
     */
    'addons': Array<StorePlanAddon>;
}
/**
 * 
 * @export
 * @interface StorePlanResponse
 */
export interface StorePlanResponse {
    /**
     * 
     * @type {PlanCollectionType}
     * @memberof StorePlanResponse
     */
    'mode': PlanCollectionType;
    /**
     * 
     * @type {Array<StorePlan>}
     * @memberof StorePlanResponse
     */
    'plans': Array<StorePlan>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const StoreType = {
    School: 'school',
    SummerSchool: 'summer_school',
    Camp: 'camp',
    RetailStore: 'retail_store',
    RetailRoute: 'retail_route'
} as const;

export type StoreType = typeof StoreType[keyof typeof StoreType];



/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccountCreationRequest} accountCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsCreate: async (accountCreationRequest: AccountCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountCreationRequest' is not null or undefined
            assertParamExists('accountsCreate', 'accountCreationRequest', accountCreationRequest)
            const localVarPath = `/api/v2/public/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ChecklistEntry} checklistEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsCreateChecklistEntry: async (id: number, checklistEntry: ChecklistEntry, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsCreateChecklistEntry', 'id', id)
            // verify required parameter 'checklistEntry' is not null or undefined
            assertParamExists('accountsCreateChecklistEntry', 'checklistEntry', checklistEntry)
            const localVarPath = `/api/v2/public/accounts/{id}/checklists`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checklistEntry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} checklistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsDownloadChecklist: async (id: number, checklistId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsDownloadChecklist', 'id', id)
            // verify required parameter 'checklistId' is not null or undefined
            assertParamExists('accountsDownloadChecklist', 'checklistId', checklistId)
            const localVarPath = `/api/v2/public/accounts/{id}/checklists/{checklistId}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"checklistId"}}`, encodeURIComponent(String(checklistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsGet', 'id', id)
            const localVarPath = `/api/v2/public/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsGetRecentOrder: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsGetRecentOrder', 'id', id)
            const localVarPath = `/api/v2/public/accounts/{id}/orders/recent`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsGetRenewalOrder: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsGetRenewalOrder', 'id', id)
            const localVarPath = `/api/v2/public/accounts/{id}/orders/renewal`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsListChecklists: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsListChecklists', 'id', id)
            const localVarPath = `/api/v2/public/accounts/{id}/checklists`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsUpdate: async (id: number, patchRequest: PatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsUpdate', 'id', id)
            // verify required parameter 'patchRequest' is not null or undefined
            assertParamExists('accountsUpdate', 'patchRequest', patchRequest)
            const localVarPath = `/api/v2/public/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AccountCreationRequest} accountCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsCreate(accountCreationRequest: AccountCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsCreate(accountCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ChecklistEntry} checklistEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsCreateChecklistEntry(id: number, checklistEntry: ChecklistEntry, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsCreateChecklistEntry(id, checklistEntry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} checklistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsDownloadChecklist(id: number, checklistId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsDownloadChecklist(id, checklistId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsGetRecentOrder(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecentOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsGetRecentOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsGetRenewalOrder(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RenewalOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsGetRenewalOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsListChecklists(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChecklistSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsListChecklists(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsUpdate(id: number, patchRequest: PatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsUpdate(id, patchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountsApiFp(configuration)
    return {
        /**
         * 
         * @param {AccountCreationRequest} accountCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsCreate(accountCreationRequest: AccountCreationRequest, options?: any): AxiosPromise<AccountCreationResponse> {
            return localVarFp.accountsCreate(accountCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ChecklistEntry} checklistEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsCreateChecklistEntry(id: number, checklistEntry: ChecklistEntry, options?: any): AxiosPromise<void> {
            return localVarFp.accountsCreateChecklistEntry(id, checklistEntry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} checklistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsDownloadChecklist(id: number, checklistId: number, options?: any): AxiosPromise<File> {
            return localVarFp.accountsDownloadChecklist(id, checklistId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsGet(id: number, options?: any): AxiosPromise<Account> {
            return localVarFp.accountsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsGetRecentOrder(id: number, options?: any): AxiosPromise<RecentOrder> {
            return localVarFp.accountsGetRecentOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsGetRenewalOrder(id: number, options?: any): AxiosPromise<RenewalOrder> {
            return localVarFp.accountsGetRenewalOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsListChecklists(id: number, options?: any): AxiosPromise<ChecklistSet> {
            return localVarFp.accountsListChecklists(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchRequest} patchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsUpdate(id: number, patchRequest: PatchRequest, options?: any): AxiosPromise<Account> {
            return localVarFp.accountsUpdate(id, patchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountsApi - interface
 * @export
 * @interface AccountsApi
 */
export interface AccountsApiInterface {
    /**
     * 
     * @param {AccountCreationRequest} accountCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsCreate(accountCreationRequest: AccountCreationRequest, options?: AxiosRequestConfig): AxiosPromise<AccountCreationResponse>;

    /**
     * 
     * @param {number} id 
     * @param {ChecklistEntry} checklistEntry 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsCreateChecklistEntry(id: number, checklistEntry: ChecklistEntry, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {number} checklistId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsDownloadChecklist(id: number, checklistId: number, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsGet(id: number, options?: AxiosRequestConfig): AxiosPromise<Account>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsGetRecentOrder(id: number, options?: AxiosRequestConfig): AxiosPromise<RecentOrder>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsGetRenewalOrder(id: number, options?: AxiosRequestConfig): AxiosPromise<RenewalOrder>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsListChecklists(id: number, options?: AxiosRequestConfig): AxiosPromise<ChecklistSet>;

    /**
     * 
     * @param {number} id 
     * @param {PatchRequest} patchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    accountsUpdate(id: number, patchRequest: PatchRequest, options?: AxiosRequestConfig): AxiosPromise<Account>;

}

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI implements AccountsApiInterface {
    /**
     * 
     * @param {AccountCreationRequest} accountCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsCreate(accountCreationRequest: AccountCreationRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsCreate(accountCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ChecklistEntry} checklistEntry 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsCreateChecklistEntry(id: number, checklistEntry: ChecklistEntry, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsCreateChecklistEntry(id, checklistEntry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} checklistId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsDownloadChecklist(id: number, checklistId: number, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsDownloadChecklist(id, checklistId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsGet(id: number, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsGetRecentOrder(id: number, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsGetRecentOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsGetRenewalOrder(id: number, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsGetRenewalOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsListChecklists(id: number, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsListChecklists(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PatchRequest} patchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsUpdate(id: number, patchRequest: PatchRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsUpdate(id, patchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CountriesApi - axios parameter creator
 * @export
 */
export const CountriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countriesList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/public/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountriesApi - functional programming interface
 * @export
 */
export const CountriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countriesList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountrySet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countriesList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CountriesApi - factory interface
 * @export
 */
export const CountriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountriesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countriesList(options?: any): AxiosPromise<CountrySet> {
            return localVarFp.countriesList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CountriesApi - interface
 * @export
 * @interface CountriesApi
 */
export interface CountriesApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApiInterface
     */
    countriesList(options?: AxiosRequestConfig): AxiosPromise<CountrySet>;

}

/**
 * CountriesApi - object-oriented interface
 * @export
 * @class CountriesApi
 * @extends {BaseAPI}
 */
export class CountriesApi extends BaseAPI implements CountriesApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public countriesList(options?: AxiosRequestConfig) {
        return CountriesApiFp(this.configuration).countriesList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerServiceApi - axios parameter creator
 * @export
 */
export const CustomerServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EmailClubRequest} emailClubRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerServiceEmailClub: async (emailClubRequest: EmailClubRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailClubRequest' is not null or undefined
            assertParamExists('customerServiceEmailClub', 'emailClubRequest', emailClubRequest)
            const localVarPath = `/api/v2/public/customerService/emailClub`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailClubRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmploymentRequest} employmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerServiceEmployment: async (employmentRequest: EmploymentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employmentRequest' is not null or undefined
            assertParamExists('customerServiceEmployment', 'employmentRequest', employmentRequest)
            const localVarPath = `/api/v2/public/customerService/employment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerServiceRequest} customerServiceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerServiceRequest: async (customerServiceRequest: CustomerServiceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerServiceRequest' is not null or undefined
            assertParamExists('customerServiceRequest', 'customerServiceRequest', customerServiceRequest)
            const localVarPath = `/api/v2/public/customerService/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerServiceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerServiceApi - functional programming interface
 * @export
 */
export const CustomerServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EmailClubRequest} emailClubRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerServiceEmailClub(emailClubRequest: EmailClubRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerServiceEmailClub(emailClubRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmploymentRequest} employmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerServiceEmployment(employmentRequest: EmploymentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerServiceEmployment(employmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomerServiceRequest} customerServiceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerServiceRequest(customerServiceRequest: CustomerServiceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerServiceRequest(customerServiceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerServiceApi - factory interface
 * @export
 */
export const CustomerServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerServiceApiFp(configuration)
    return {
        /**
         * 
         * @param {EmailClubRequest} emailClubRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerServiceEmailClub(emailClubRequest: EmailClubRequest, options?: any): AxiosPromise<void> {
            return localVarFp.customerServiceEmailClub(emailClubRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmploymentRequest} employmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerServiceEmployment(employmentRequest: EmploymentRequest, options?: any): AxiosPromise<void> {
            return localVarFp.customerServiceEmployment(employmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerServiceRequest} customerServiceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerServiceRequest(customerServiceRequest: CustomerServiceRequest, options?: any): AxiosPromise<void> {
            return localVarFp.customerServiceRequest(customerServiceRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerServiceApi - interface
 * @export
 * @interface CustomerServiceApi
 */
export interface CustomerServiceApiInterface {
    /**
     * 
     * @param {EmailClubRequest} emailClubRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerServiceApiInterface
     */
    customerServiceEmailClub(emailClubRequest: EmailClubRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {EmploymentRequest} employmentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerServiceApiInterface
     */
    customerServiceEmployment(employmentRequest: EmploymentRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {CustomerServiceRequest} customerServiceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerServiceApiInterface
     */
    customerServiceRequest(customerServiceRequest: CustomerServiceRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * CustomerServiceApi - object-oriented interface
 * @export
 * @class CustomerServiceApi
 * @extends {BaseAPI}
 */
export class CustomerServiceApi extends BaseAPI implements CustomerServiceApiInterface {
    /**
     * 
     * @param {EmailClubRequest} emailClubRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerServiceApi
     */
    public customerServiceEmailClub(emailClubRequest: EmailClubRequest, options?: AxiosRequestConfig) {
        return CustomerServiceApiFp(this.configuration).customerServiceEmailClub(emailClubRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmploymentRequest} employmentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerServiceApi
     */
    public customerServiceEmployment(employmentRequest: EmploymentRequest, options?: AxiosRequestConfig) {
        return CustomerServiceApiFp(this.configuration).customerServiceEmployment(employmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerServiceRequest} customerServiceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerServiceApi
     */
    public customerServiceRequest(customerServiceRequest: CustomerServiceRequest, options?: AxiosRequestConfig) {
        return CustomerServiceApiFp(this.configuration).customerServiceRequest(customerServiceRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DiscountsApi - axios parameter creator
 * @export
 */
export const DiscountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} code 
         * @param {number} [storeId] 
         * @param {number} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsGet: async (code: string, storeId?: number, planId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('discountsGet', 'code', code)
            const localVarPath = `/api/v2/public/discountCodes/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (planId !== undefined) {
                localVarQueryParameter['planId'] = planId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiscountsApi - functional programming interface
 * @export
 */
export const DiscountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiscountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {number} [storeId] 
         * @param {number} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountsGet(code: string, storeId?: number, planId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountCode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountsGet(code, storeId, planId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DiscountsApi - factory interface
 * @export
 */
export const DiscountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiscountsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {number} [storeId] 
         * @param {number} [planId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsGet(code: string, storeId?: number, planId?: number, options?: any): AxiosPromise<DiscountCode> {
            return localVarFp.discountsGet(code, storeId, planId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiscountsApi - interface
 * @export
 * @interface DiscountsApi
 */
export interface DiscountsApiInterface {
    /**
     * 
     * @param {string} code 
     * @param {number} [storeId] 
     * @param {number} [planId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApiInterface
     */
    discountsGet(code: string, storeId?: number, planId?: number, options?: AxiosRequestConfig): AxiosPromise<DiscountCode>;

}

/**
 * DiscountsApi - object-oriented interface
 * @export
 * @class DiscountsApi
 * @extends {BaseAPI}
 */
export class DiscountsApi extends BaseAPI implements DiscountsApiInterface {
    /**
     * 
     * @param {string} code 
     * @param {number} [storeId] 
     * @param {number} [planId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountsGet(code: string, storeId?: number, planId?: number, options?: AxiosRequestConfig) {
        return DiscountsApiFp(this.configuration).discountsGet(code, storeId, planId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocsApi - axios parameter creator
 * @export
 */
export const DocsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsDocs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/public/api-docs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocsApi - functional programming interface
 * @export
 */
export const DocsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async docsDocs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.docsDocs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocsApi - factory interface
 * @export
 */
export const DocsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsDocs(options?: any): AxiosPromise<void> {
            return localVarFp.docsDocs(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocsApi - interface
 * @export
 * @interface DocsApi
 */
export interface DocsApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApiInterface
     */
    docsDocs(options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * DocsApi - object-oriented interface
 * @export
 * @class DocsApi
 * @extends {BaseAPI}
 */
export class DocsApi extends BaseAPI implements DocsApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public docsDocs(options?: AxiosRequestConfig) {
        return DocsApiFp(this.configuration).docsDocs(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthReady: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/public/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthReady(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthReady(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthReady(options?: any): AxiosPromise<void> {
            return localVarFp.healthReady(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - interface
 * @export
 * @interface HealthApi
 */
export interface HealthApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApiInterface
     */
    healthReady(options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI implements HealthApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public healthReady(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).healthReady(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LookupsApi - axios parameter creator
 * @export
 */
export const LookupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} filter 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupsAccounts: async (filter: string, storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('lookupsAccounts', 'filter', filter)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('lookupsAccounts', 'storeId', storeId)
            const localVarPath = `/api/v2/public/lookups/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupsStores: async (filter: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('lookupsStores', 'filter', filter)
            const localVarPath = `/api/v2/public/lookups/stores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LookupsApi - functional programming interface
 * @export
 */
export const LookupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LookupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} filter 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupsAccounts(filter: string, storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountLookupSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupsAccounts(filter, storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupsStores(filter: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreLookupSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupsStores(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LookupsApi - factory interface
 * @export
 */
export const LookupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LookupsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} filter 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupsAccounts(filter: string, storeId: number, options?: any): AxiosPromise<AccountLookupSet> {
            return localVarFp.lookupsAccounts(filter, storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupsStores(filter: string, options?: any): AxiosPromise<StoreLookupSet> {
            return localVarFp.lookupsStores(filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LookupsApi - interface
 * @export
 * @interface LookupsApi
 */
export interface LookupsApiInterface {
    /**
     * 
     * @param {string} filter 
     * @param {number} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LookupsApiInterface
     */
    lookupsAccounts(filter: string, storeId: number, options?: AxiosRequestConfig): AxiosPromise<AccountLookupSet>;

    /**
     * 
     * @param {string} filter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LookupsApiInterface
     */
    lookupsStores(filter: string, options?: AxiosRequestConfig): AxiosPromise<StoreLookupSet>;

}

/**
 * LookupsApi - object-oriented interface
 * @export
 * @class LookupsApi
 * @extends {BaseAPI}
 */
export class LookupsApi extends BaseAPI implements LookupsApiInterface {
    /**
     * 
     * @param {string} filter 
     * @param {number} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LookupsApi
     */
    public lookupsAccounts(filter: string, storeId: number, options?: AxiosRequestConfig) {
        return LookupsApiFp(this.configuration).lookupsAccounts(filter, storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} filter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LookupsApi
     */
    public lookupsStores(filter: string, options?: AxiosRequestConfig) {
        return LookupsApiFp(this.configuration).lookupsStores(filter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MetaApi - axios parameter creator
 * @export
 */
export const MetaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PricingRequest} pricingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaPricingRequested: async (pricingRequest: PricingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pricingRequest' is not null or undefined
            assertParamExists('metaPricingRequested', 'pricingRequest', pricingRequest)
            const localVarPath = `/api/v2/public/meta/pricingRequested`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pricingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetaApi - functional programming interface
 * @export
 */
export const MetaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PricingRequest} pricingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaPricingRequested(pricingRequest: PricingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaPricingRequested(pricingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MetaApi - factory interface
 * @export
 */
export const MetaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetaApiFp(configuration)
    return {
        /**
         * 
         * @param {PricingRequest} pricingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaPricingRequested(pricingRequest: PricingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.metaPricingRequested(pricingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetaApi - interface
 * @export
 * @interface MetaApi
 */
export interface MetaApiInterface {
    /**
     * 
     * @param {PricingRequest} pricingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApiInterface
     */
    metaPricingRequested(pricingRequest: PricingRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * MetaApi - object-oriented interface
 * @export
 * @class MetaApi
 * @extends {BaseAPI}
 */
export class MetaApi extends BaseAPI implements MetaApiInterface {
    /**
     * 
     * @param {PricingRequest} pricingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public metaPricingRequested(pricingRequest: PricingRequest, options?: AxiosRequestConfig) {
        return MetaApiFp(this.configuration).metaPricingRequested(pricingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OrderRequest} orderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersCreate: async (orderRequest: OrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderRequest' is not null or undefined
            assertParamExists('ordersCreate', 'orderRequest', orderRequest)
            const localVarPath = `/api/v2/public/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderPlanRequest} orderPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPlan: async (orderPlanRequest: OrderPlanRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderPlanRequest' is not null or undefined
            assertParamExists('ordersPlan', 'orderPlanRequest', orderPlanRequest)
            const localVarPath = `/api/v2/public/orders/plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderPlanRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {OrderRequest} orderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersCreate(orderRequest: OrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersCreate(orderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrderPlanRequest} orderPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersPlan(orderPlanRequest: OrderPlanRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersPlan(orderPlanRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersApiFp(configuration)
    return {
        /**
         * 
         * @param {OrderRequest} orderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersCreate(orderRequest: OrderRequest, options?: any): AxiosPromise<Order> {
            return localVarFp.ordersCreate(orderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrderPlanRequest} orderPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersPlan(orderPlanRequest: OrderPlanRequest, options?: any): AxiosPromise<OrderPlan> {
            return localVarFp.ordersPlan(orderPlanRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersApi - interface
 * @export
 * @interface OrdersApi
 */
export interface OrdersApiInterface {
    /**
     * 
     * @param {OrderRequest} orderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApiInterface
     */
    ordersCreate(orderRequest: OrderRequest, options?: AxiosRequestConfig): AxiosPromise<Order>;

    /**
     * 
     * @param {OrderPlanRequest} orderPlanRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApiInterface
     */
    ordersPlan(orderPlanRequest: OrderPlanRequest, options?: AxiosRequestConfig): AxiosPromise<OrderPlan>;

}

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI implements OrdersApiInterface {
    /**
     * 
     * @param {OrderRequest} orderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersCreate(orderRequest: OrderRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersCreate(orderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderPlanRequest} orderPlanRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersPlan(orderPlanRequest: OrderPlanRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersPlan(orderPlanRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlansApi - axios parameter creator
 * @export
 */
export const PlansApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansListAddons: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('plansListAddons', 'id', id)
            const localVarPath = `/api/v2/public/plans/{id}/addons`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlansApi - functional programming interface
 * @export
 */
export const PlansApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlansApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plansListAddons(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorePlanAddonSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plansListAddons(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlansApi - factory interface
 * @export
 */
export const PlansApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlansApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansListAddons(id: number, options?: any): AxiosPromise<StorePlanAddonSet> {
            return localVarFp.plansListAddons(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlansApi - interface
 * @export
 * @interface PlansApi
 */
export interface PlansApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApiInterface
     */
    plansListAddons(id: number, options?: AxiosRequestConfig): AxiosPromise<StorePlanAddonSet>;

}

/**
 * PlansApi - object-oriented interface
 * @export
 * @class PlansApi
 * @extends {BaseAPI}
 */
export class PlansApi extends BaseAPI implements PlansApiInterface {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlansApi
     */
    public plansListAddons(id: number, options?: AxiosRequestConfig) {
        return PlansApiFp(this.configuration).plansListAddons(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PostalApi - axios parameter creator
 * @export
 */
export const PostalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} postalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postalLookup: async (postalCode: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postalCode' is not null or undefined
            assertParamExists('postalLookup', 'postalCode', postalCode)
            const localVarPath = `/api/v2/public/postal/{postalCode}`
                .replace(`{${"postalCode"}}`, encodeURIComponent(String(postalCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostalApi - functional programming interface
 * @export
 */
export const PostalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PostalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} postalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postalLookup(postalCode: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostalData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postalLookup(postalCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PostalApi - factory interface
 * @export
 */
export const PostalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PostalApiFp(configuration)
    return {
        /**
         * 
         * @param {number} postalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postalLookup(postalCode: number, options?: any): AxiosPromise<PostalData> {
            return localVarFp.postalLookup(postalCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PostalApi - interface
 * @export
 * @interface PostalApi
 */
export interface PostalApiInterface {
    /**
     * 
     * @param {number} postalCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostalApiInterface
     */
    postalLookup(postalCode: number, options?: AxiosRequestConfig): AxiosPromise<PostalData>;

}

/**
 * PostalApi - object-oriented interface
 * @export
 * @class PostalApi
 * @extends {BaseAPI}
 */
export class PostalApi extends BaseAPI implements PostalApiInterface {
    /**
     * 
     * @param {number} postalCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostalApi
     */
    public postalLookup(postalCode: number, options?: AxiosRequestConfig) {
        return PostalApiFp(this.configuration).postalLookup(postalCode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SessionsApi - axios parameter creator
 * @export
 */
export const SessionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionsDeleteCookie: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/public/sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionsExtendSession: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/public/sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SessionRequest} sessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionsRequestSession: async (sessionRequest: SessionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionRequest' is not null or undefined
            assertParamExists('sessionsRequestSession', 'sessionRequest', sessionRequest)
            const localVarPath = `/api/v2/public/sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sessionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SessionsApi - functional programming interface
 * @export
 */
export const SessionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SessionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionsDeleteCookie(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Session>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sessionsDeleteCookie(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionsExtendSession(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Session>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sessionsExtendSession(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SessionRequest} sessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionsRequestSession(sessionRequest: SessionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Session>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sessionsRequestSession(sessionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SessionsApi - factory interface
 * @export
 */
export const SessionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SessionsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionsDeleteCookie(options?: any): AxiosPromise<Session> {
            return localVarFp.sessionsDeleteCookie(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionsExtendSession(options?: any): AxiosPromise<Session> {
            return localVarFp.sessionsExtendSession(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SessionRequest} sessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionsRequestSession(sessionRequest: SessionRequest, options?: any): AxiosPromise<Session> {
            return localVarFp.sessionsRequestSession(sessionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SessionsApi - interface
 * @export
 * @interface SessionsApi
 */
export interface SessionsApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    sessionsDeleteCookie(options?: AxiosRequestConfig): AxiosPromise<Session>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    sessionsExtendSession(options?: AxiosRequestConfig): AxiosPromise<Session>;

    /**
     * 
     * @param {SessionRequest} sessionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApiInterface
     */
    sessionsRequestSession(sessionRequest: SessionRequest, options?: AxiosRequestConfig): AxiosPromise<Session>;

}

/**
 * SessionsApi - object-oriented interface
 * @export
 * @class SessionsApi
 * @extends {BaseAPI}
 */
export class SessionsApi extends BaseAPI implements SessionsApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public sessionsDeleteCookie(options?: AxiosRequestConfig) {
        return SessionsApiFp(this.configuration).sessionsDeleteCookie(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public sessionsExtendSession(options?: AxiosRequestConfig) {
        return SessionsApiFp(this.configuration).sessionsExtendSession(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SessionRequest} sessionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public sessionsRequestSession(sessionRequest: SessionRequest, options?: AxiosRequestConfig) {
        return SessionsApiFp(this.configuration).sessionsRequestSession(sessionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StoresApi - axios parameter creator
 * @export
 */
export const StoresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} prefix 
         * @param {CheckPassPhraseRequest} checkPassPhraseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesCheckPassPhrase: async (prefix: string, checkPassPhraseRequest: CheckPassPhraseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prefix' is not null or undefined
            assertParamExists('storesCheckPassPhrase', 'prefix', prefix)
            // verify required parameter 'checkPassPhraseRequest' is not null or undefined
            assertParamExists('storesCheckPassPhrase', 'checkPassPhraseRequest', checkPassPhraseRequest)
            const localVarPath = `/api/v2/public/stores/{prefix}/passphrase`
                .replace(`{${"prefix"}}`, encodeURIComponent(String(prefix)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkPassPhraseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storesGet', 'id', id)
            const localVarPath = `/api/v2/public/stores/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGetCurrentProration: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storesGetCurrentProration', 'id', id)
            const localVarPath = `/api/v2/public/stores/{id}/currentProration`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGetLocations: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storesGetLocations', 'id', id)
            const localVarPath = `/api/v2/public/stores/{id}/locations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGetPaymentPlan: async (storeId: number, planId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storesGetPaymentPlan', 'storeId', storeId)
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('storesGetPaymentPlan', 'planId', planId)
            const localVarPath = `/api/v2/public/stores/{storeId}/paymentPlans/{planId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"planId"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prefix 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGetPrefix: async (prefix: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prefix' is not null or undefined
            assertParamExists('storesGetPrefix', 'prefix', prefix)
            const localVarPath = `/api/v2/public/stores/prefixes/{prefix}`
                .replace(`{${"prefix"}}`, encodeURIComponent(String(prefix)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [faculty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesList: async (faculty?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/public/stores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (faculty !== undefined) {
                localVarQueryParameter['faculty'] = faculty;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesListPaymentPlans: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storesListPaymentPlans', 'id', id)
            const localVarPath = `/api/v2/public/stores/{id}/paymentPlans`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [accountId] 
         * @param {string} [isRenewal] 
         * @param {string} [isFaculty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesListPlans: async (id: number, accountId?: string, isRenewal?: string, isFaculty?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storesListPlans', 'id', id)
            const localVarPath = `/api/v2/public/stores/{id}/plans`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (isRenewal !== undefined) {
                localVarQueryParameter['isRenewal'] = isRenewal;
            }

            if (isFaculty !== undefined) {
                localVarQueryParameter['isFaculty'] = isFaculty;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoresApi - functional programming interface
 * @export
 */
export const StoresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoresApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} prefix 
         * @param {CheckPassPhraseRequest} checkPassPhraseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesCheckPassPhrase(prefix: string, checkPassPhraseRequest: CheckPassPhraseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesCheckPassPhrase(prefix, checkPassPhraseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Store>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesGetCurrentProration(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Proration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesGetCurrentProration(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesGetLocations(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesGetLocations(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesGetPaymentPlan(storeId: number, planId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesGetPaymentPlan(storeId, planId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} prefix 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesGetPrefix(prefix: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Store>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesGetPrefix(prefix, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [faculty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesList(faculty?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinimalStoreSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesList(faculty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesListPaymentPlans(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentPlanSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesListPaymentPlans(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [accountId] 
         * @param {string} [isRenewal] 
         * @param {string} [isFaculty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesListPlans(id: number, accountId?: string, isRenewal?: string, isFaculty?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorePlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesListPlans(id, accountId, isRenewal, isFaculty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoresApi - factory interface
 * @export
 */
export const StoresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoresApiFp(configuration)
    return {
        /**
         * 
         * @param {string} prefix 
         * @param {CheckPassPhraseRequest} checkPassPhraseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesCheckPassPhrase(prefix: string, checkPassPhraseRequest: CheckPassPhraseRequest, options?: any): AxiosPromise<void> {
            return localVarFp.storesCheckPassPhrase(prefix, checkPassPhraseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGet(id: number, options?: any): AxiosPromise<Store> {
            return localVarFp.storesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGetCurrentProration(id: number, options?: any): AxiosPromise<Proration> {
            return localVarFp.storesGetCurrentProration(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGetLocations(id: number, options?: any): AxiosPromise<LocationSet> {
            return localVarFp.storesGetLocations(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGetPaymentPlan(storeId: number, planId: number, options?: any): AxiosPromise<PaymentPlan> {
            return localVarFp.storesGetPaymentPlan(storeId, planId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prefix 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesGetPrefix(prefix: string, options?: any): AxiosPromise<Store> {
            return localVarFp.storesGetPrefix(prefix, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [faculty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesList(faculty?: boolean, options?: any): AxiosPromise<MinimalStoreSet> {
            return localVarFp.storesList(faculty, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesListPaymentPlans(id: number, options?: any): AxiosPromise<PaymentPlanSet> {
            return localVarFp.storesListPaymentPlans(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [accountId] 
         * @param {string} [isRenewal] 
         * @param {string} [isFaculty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesListPlans(id: number, accountId?: string, isRenewal?: string, isFaculty?: string, options?: any): AxiosPromise<StorePlanResponse> {
            return localVarFp.storesListPlans(id, accountId, isRenewal, isFaculty, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoresApi - interface
 * @export
 * @interface StoresApi
 */
export interface StoresApiInterface {
    /**
     * 
     * @param {string} prefix 
     * @param {CheckPassPhraseRequest} checkPassPhraseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesCheckPassPhrase(prefix: string, checkPassPhraseRequest: CheckPassPhraseRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesGet(id: number, options?: AxiosRequestConfig): AxiosPromise<Store>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesGetCurrentProration(id: number, options?: AxiosRequestConfig): AxiosPromise<Proration>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesGetLocations(id: number, options?: AxiosRequestConfig): AxiosPromise<LocationSet>;

    /**
     * 
     * @param {number} storeId 
     * @param {number} planId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesGetPaymentPlan(storeId: number, planId: number, options?: AxiosRequestConfig): AxiosPromise<PaymentPlan>;

    /**
     * 
     * @param {string} prefix 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesGetPrefix(prefix: string, options?: AxiosRequestConfig): AxiosPromise<Store>;

    /**
     * 
     * @param {boolean} [faculty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesList(faculty?: boolean, options?: AxiosRequestConfig): AxiosPromise<MinimalStoreSet>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesListPaymentPlans(id: number, options?: AxiosRequestConfig): AxiosPromise<PaymentPlanSet>;

    /**
     * 
     * @param {number} id 
     * @param {string} [accountId] 
     * @param {string} [isRenewal] 
     * @param {string} [isFaculty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    storesListPlans(id: number, accountId?: string, isRenewal?: string, isFaculty?: string, options?: AxiosRequestConfig): AxiosPromise<StorePlanResponse>;

}

/**
 * StoresApi - object-oriented interface
 * @export
 * @class StoresApi
 * @extends {BaseAPI}
 */
export class StoresApi extends BaseAPI implements StoresApiInterface {
    /**
     * 
     * @param {string} prefix 
     * @param {CheckPassPhraseRequest} checkPassPhraseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesCheckPassPhrase(prefix: string, checkPassPhraseRequest: CheckPassPhraseRequest, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesCheckPassPhrase(prefix, checkPassPhraseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesGet(id: number, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesGetCurrentProration(id: number, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesGetCurrentProration(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesGetLocations(id: number, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesGetLocations(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId 
     * @param {number} planId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesGetPaymentPlan(storeId: number, planId: number, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesGetPaymentPlan(storeId, planId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prefix 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesGetPrefix(prefix: string, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesGetPrefix(prefix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [faculty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesList(faculty?: boolean, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesList(faculty, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesListPaymentPlans(id: number, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesListPaymentPlans(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [accountId] 
     * @param {string} [isRenewal] 
     * @param {string} [isFaculty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public storesListPlans(id: number, accountId?: string, isRenewal?: string, isFaculty?: string, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).storesListPlans(id, accountId, isRenewal, isFaculty, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersExists: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('usersExists', 'email', email)
            const localVarPath = `/api/v2/public/users/exists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/public/users/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersResetPassword: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('usersResetPassword', 'email', email)
            const localVarPath = `/api/v2/public/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PasswordChangeRequest} passwordChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdatePassword: async (passwordChangeRequest: PasswordChangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordChangeRequest' is not null or undefined
            assertParamExists('usersUpdatePassword', 'passwordChangeRequest', passwordChangeRequest)
            const localVarPath = `/api/v2/public/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersExists(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersExists(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersListAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkedAccountSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersListAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersResetPassword(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersResetPassword(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PasswordChangeRequest} passwordChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUpdatePassword(passwordChangeRequest: PasswordChangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUpdatePassword(passwordChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersExists(email: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersExists(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListAccounts(options?: any): AxiosPromise<LinkedAccountSet> {
            return localVarFp.usersListAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersResetPassword(email: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersResetPassword(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PasswordChangeRequest} passwordChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdatePassword(passwordChangeRequest: PasswordChangeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.usersUpdatePassword(passwordChangeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - interface
 * @export
 * @interface UsersApi
 */
export interface UsersApiInterface {
    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersExists(email: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersListAccounts(options?: AxiosRequestConfig): AxiosPromise<LinkedAccountSet>;

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersResetPassword(email: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {PasswordChangeRequest} passwordChangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersUpdatePassword(passwordChangeRequest: PasswordChangeRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI implements UsersApiInterface {
    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersExists(email: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersExists(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersListAccounts(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersListAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersResetPassword(email: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersResetPassword(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PasswordChangeRequest} passwordChangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUpdatePassword(passwordChangeRequest: PasswordChangeRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUpdatePassword(passwordChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


