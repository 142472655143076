import { useEffect, useState } from "react"
import { setIsLoading } from "../../../features/menus/AppMenu"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../app/store"
import { showError } from "../../../features/errors/Errors"
import { PaymentPlan, StorePlanAddon } from "../../../apis/ersys-public"
import { useErsysPublicApi } from "../../ErsysPublicApiProvider"
import AddonComponent from "../AddonComponent/AddonComponent"
import { Button, Container, Grid, RadioGroup } from "@mui/material"

import "./AddonsStep.scss"
import PaymentPlanComponent from "../PaymentPlanComponent/PaymentPlanComponent"
import {
  setOrderStepIndex,
  setSelectedAddons,
  setSelectedPaymentPlan,
} from "../../../features/order-workflow/Order"
import { NavigateNext } from "@mui/icons-material"

interface AddonsStepProps {
  currentIndex: number
}

const AddonsStep: React.FC<AddonsStepProps> = ({ currentIndex }) => {
  const ersysPublicApiServices = useErsysPublicApi()
  const dispatch = useDispatch()
  const plan = useSelector((state: RootState) => state.order.selectedStorePlan)
  const account = useSelector((state: RootState) => state.order.selectedAccount)
  const isRenewal = useSelector((state: RootState) => state.order.isRenewal)
  const accountNeedsCreation = useSelector(
    (state: RootState) => state.order.accountNeedsCreation,
  )
  const storeID = useSelector((state: RootState) => state.order.selectedStore)
  const proration = useSelector((state: RootState) => state.order.proration)
  const [payAtOnce] = useState<PaymentPlan>({
    id: -1,
    publicName: "Pay in Full",
    adminFee: 0,
    dates: [],
    firstPaymentPercentage: 100,
    isPublic: true,
    isRenewalOnly: false,
    isUpgradeOnly: false,
    validUntil: "",
    name: "",
  })
  const [addons, setAddons] = useState<StorePlanAddon[]>([])
  const [paymentPlans, setPaymentPlans] = useState<PaymentPlan[]>([])
  const [selectedLocalPaymentPlan, setSelectedLocalPaymentPlan] =
    useState<PaymentPlan>(payAtOnce)
  const [selectedLocalAddons, setSelectedLocalAddons] = useState<
    StorePlanAddon[]
  >([])

  useEffect(() => {
    let foundExtras = false
    const fetch = async () => {
      try {
        if (!plan || (!account && !accountNeedsCreation)) {
          dispatch(setOrderStepIndex(currentIndex - 1))
        }

        const addonResponse =
          await ersysPublicApiServices.plansService.plansListAddons(
            plan!.id,
          )
        setAddons(addonResponse.data.addons)
        const preselectedAddons = []
        for (const addon of addonResponse.data.addons) {
          if (addon.currentCost === 0) {
            preselectedAddons.push(addon)
          }
        }
        setSelectedLocalAddons(preselectedAddons)

        if (addonResponse.data.addons.length > 0) {
          foundExtras = true
        }

        const paymentPlansResponse =
          await ersysPublicApiServices.storesService.storesListPaymentPlans(
            storeID!,
          )
        //todo: currently this is only renewals
        setPaymentPlans(
          paymentPlansResponse.data.plans.filter(
            (p) => p.isRenewalOnly === isRenewal,
          ),
        )

        if (paymentPlansResponse.data.plans.length > 0) {
          foundExtras = true
        }
      } catch (ex: any) {
        dispatch(
          showError({
            title: "Unexpected Error occurred",
            message:
              "An unexpected error occurred while getting addon data. If problem persists please contact IT.",
          }),
        )
        console.log(ex)
      } finally {
        dispatch(setIsLoading(false))
      }

      //if neither addons nor payment plans exist... skip this page
      if (!foundExtras) {
        dispatch(setSelectedAddons([]))
        dispatch(setOrderStepIndex(currentIndex + 1))
      }
    }
    fetch()
  }, [
    dispatch,
    isRenewal,
    ersysPublicApiServices.storesService,
    ersysPublicApiServices.plansService,
    plan,
    account,
    storeID,
    accountNeedsCreation,
    currentIndex,
  ])

  const onPaymentPlanChange = (id: number) => {
    if (id === payAtOnce.id) {
      setSelectedLocalPaymentPlan(payAtOnce)
      return
    }

    setSelectedLocalPaymentPlan(paymentPlans.filter((p) => p.id === id)[0])
  }

  const goNext = () => {
    dispatch(setSelectedAddons(selectedLocalAddons))
    if (selectedLocalPaymentPlan.id === payAtOnce.id) {
      dispatch(setSelectedPaymentPlan(undefined))
    } else {
      dispatch(setSelectedPaymentPlan(selectedLocalPaymentPlan))
    }

    dispatch(setOrderStepIndex(currentIndex + 1))
  }

  const selectPlan = (id: number) => {
    if (selectedLocalAddons.some((p) => p.id === id)) {
      return
    }
    const newSet: StorePlanAddon[] = []
    newSet.push(...selectedLocalAddons)
    newSet.push(addons.filter((a) => a.id === id)[0])
    setSelectedLocalAddons(newSet)
  }

  const deselectPlan = (id: number) => {
    setSelectedLocalAddons(selectedLocalAddons.filter((a) => a.id !== id))
  }

  return (
    <>
      <Container maxWidth="md">
        <div className="extra-grid-wrapper">
          <Grid container spacing={2}>
            {addons && addons.length > 0 && (
              <Grid item xs={6}>
                <h2 className="extras-label">Addons</h2>
                <ul className="extras-list">
                  {addons.map((a) => (
                    <li key={a.id}>
                      <AddonComponent
                        proration={proration}
                        addon={a}
                        onChecked={selectPlan}
                        onUnchecked={deselectPlan}
                      />
                    </li>
                  ))}
                </ul>
              </Grid>
            )}

            {paymentPlans && paymentPlans.length > 0 && (
              <Grid item xs={6}>
                <h2 className="extras-label">Payment Plans</h2>
                <RadioGroup
                  name="payment-plan-group"
                  value={selectedLocalPaymentPlan.id}
                  onChange={(e) => {
                    onPaymentPlanChange(parseInt(e.target.value, 10))
                  }}
                >
                  <ul className="extras-list payment-plan-list">
                    <li>
                      <PaymentPlanComponent paymentPlan={payAtOnce} />
                    </li>
                    {paymentPlans.map((p) => (
                      <li key={p.id}>
                        <PaymentPlanComponent paymentPlan={p} />
                      </li>
                    ))}
                  </ul>
                </RadioGroup>
              </Grid>
            )}
          </Grid>
        </div>

        <div className="select-account-btn-wrapper">
          <Button
            variant="contained"
            onClick={goNext}
            endIcon={<NavigateNext />}
          >
            Continue
          </Button>
        </div>
      </Container>
    </>
  )
}

export default AddonsStep
